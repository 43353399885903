/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import Modal from './Modal';
import Slide from '@material-ui/core/Slide';
import Badge from '@material-ui/core/Badge';
import ShopIcon from './ShopIcon';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import CloseIcon from '@material-ui/icons/Close';
import AppButton from './AppButton';
import ShoppingCartCard from './ShoppingCartCard';
import {
  addNotification,
  changeCountItemInShoppingCart,
  getShoppingCart,
  removeItemInShoppngCart,
  resetShoppingCart,
  toggleShoppingCart,
} from '../redux/actions';
import api from '../services/api';
import {getCroppedPicture} from '../modules/imageHandler';
import numeral from 'numeral';
import CurrencyFormat from 'react-currency-format';
import {useHistory} from 'react-router-dom';
import {SELECTOR_UI_SHOW_SHOPPING_CART} from '../redux/selectors/uiConfig';
import {SELECTOR_SHOPPING_CART_PRODUCTS_COUNT} from '../redux/selectors/shoppingCart';
import {getProductPrice} from '../util/product';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    minWidth: 350,
    position: 'fixed',
    top: 0,
    right: 0,
    boxShadow: '0px 3px 6px #00000029',
    zIndex: 2000,
    backgroundColor: theme.palette.common.white,
  },
  back: {
    position: 'fixed',
    height: '100vh',
    width: '100vw',
    top: 0,
    left: 0,
    zIndex: 98,
  },
  title: {
    fontSize: '.8rem',
    paddingLeft: 4,
  },
  link: {
    textDecoration: 'underline',
    fontWeight: 300,
    paddingTop: 0,
    fontSize: '.7rem',
  },
  close: {
    marginTop: -14,
    height: 'min-content',
  },
  subtitle: {
    fontSize: '.8rem',
  },
  content: {
    overflowY: 'auto',
    minHeight: 'calc(100% - 300px)',
    maxHeight: 'calc(100% - 300px)',
  },
}));

function debounce(func, wait) {
  let timeout;
  return function (...args) {
    const context = this;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      func.apply(context, args);
    }, wait);
  };
}

const ShoppingCart = () => {
  const classes = useStyles();
  const openShoppingCart = useSelector(SELECTOR_UI_SHOW_SHOPPING_CART);
  const productsCount = useSelector(SELECTOR_SHOPPING_CART_PRODUCTS_COUNT);
  const dispatch = useDispatch();
  const history = useHistory();
  const [isDisabled, setIsDisabled] = useState(false);
  const [details, setDetails] = useState({
    products: [],
    totalProducts: 0,
    shoppingCartId: -1,
    subTotal: 0,
    tax: 0,
  });

  const handleClose = () => {
    dispatch(toggleShoppingCart(false));
  };

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    fetchShoppingCart();
  }, [openShoppingCart]);

  const fetchShoppingCart = async () => {
    dispatch(getShoppingCart()).then((cart) => {
      let prices = [0, 0];
      let tax = [0, 0];
      if (cart) {
        const products = cart.shopping_cart_details;
        setDetails({
          ...details,
          products: products,
          totalProducts: products.length,
          subTotal: 0,
          tax: 0,
        });

        prices = products.map((item) => item.quantity * getProductPrice(item.product).price_with_tax);
        tax = products.map(
          (item) => item.quantity * getProductPrice(item.product).price * (item.product.tax_rule_value / 100),
        );

        setIsDisabled(products.some((it) => it.product.status === 'deleted' || it.product.quantity <= 0));

        setDetails({
          shoppingCartId: cart.id,
          products: products,
          totalProducts: products.length,
          subTotal: prices && prices.length ? Math.trunc(prices.reduce((acc, current) => acc + current, 0)) : 0,
          tax: tax && tax.length ? Math.trunc(tax.reduce((acc, current) => acc + current, 0)) : 0,
        });
      }
    });
  };

  const removeProduct = async (id) => {
    await dispatch(removeItemInShoppngCart(id));
    await fetchShoppingCart();
  };

  const deleteShoppingCart = async () => {
    const {shoppingCartId} = details;
    try {
      await api.shoppingCart.remove(shoppingCartId);
      dispatch(resetShoppingCart());
      dispatch(addNotification('Carrito eliminado con exito !', 'success'));
      setDetails({
        products: [],
        totalProducts: 0,
        shoppingCartId: -1,
        subTotal: 0,
        tax: 0,
      });
    } catch (e) {
      dispatch(addNotification(e.message, 'error'));
    } finally {
      await fetchShoppingCart();
    }
  };

  const pathCountProduct = async (id, quantity) => {
    await dispatch(changeCountItemInShoppingCart(id, quantity));
    await fetchShoppingCart();
  };

  const handleCheckout = () => {
    handleClose();
    history.push('/checkout/info');
  };
  const debouncePathCountProduct = React.useCallback(debounce(pathCountProduct, 400), []);

  const ProductOversize = details?.products?.some((item) => item.product.oversize === 'true');

  return (
    <Modal>
      {openShoppingCart && <div className={classes.back} onClick={handleClose} />}
      <Slide direction="left" in={openShoppingCart}>
        <div className={classes.root}>
          <Box pl={3} pr={1} py={2} display="flex" justifyContent="space-between">
            <Box display="flex">
              <Box mt={1} clone>
                <Badge
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  badgeContent={details.totalProducts}
                  color="secondary"
                >
                  <ShopIcon fontSize="small" color="secondary" />
                </Badge>
              </Box>
              <Box display="flex" alignItems="flex-start" flexDirection="column">
                <Typography className={classes.title} color="textPrimary">
                  Carrito de compras: {details.totalProducts} producto
                </Typography>
                <Button onClick={deleteShoppingCart} size="small" variant="text" className={classes.link}>
                  Limpiar carrito
                </Button>
              </Box>
            </Box>
            <IconButton className={classes.close} onClick={handleClose} aria-label="close">
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
          <Divider />
          <Box flexDirection="column" pl={2} pr={1} py={2} className={classes.content}>
            {details.products.map((item) => (
              <ShoppingCartCard
                key={item.product.id}
                title={item.product.name}
                handleOnChangeNumeric={(value) => debouncePathCountProduct(item.id, value)}
                handleDelete={() => removeProduct(item.id)}
                count={item.quantity}
                price={numeral(getProductPrice(item.product).price).format('0,0')}
                image={getCroppedPicture(item.product.main_media?.path, {
                  width: 77,
                  height: 77,
                })}
                status={item.product.status}
                quantity={item.product.quantity}
                productId={item.product.id}
                oversize={item.product.oversize}
              />
            ))}
          </Box>
          <Box pt={2} pb={3}>
            <Box clone mb={2}>
              <Divider />
            </Box>
            {ProductOversize ? (
              <p style={{lineHeight: 1.2, fontSize: 13, padding: '0px 10px', color: 'red'}}>
                Los productos oversize no aplican para envío gratis
              </p>
            ) : null}
            <Box display="flex" justifyContent="space-between" px={1}>
              <Typography className={classes.subtitle} variant="subtitle1">
                Subtotal:
              </Typography>
              <CurrencyFormat
                value={details.subTotal - details.tax}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
                renderText={(value) => (
                  <Typography className={classes.subtitle} variant="subtitle1">
                    {value}
                  </Typography>
                )}
              />
            </Box>
            <Box display="flex" justifyContent="space-between" px={1}>
              <Typography className={classes.subtitle} variant="subtitle1">
                *Envío:
              </Typography>
              <Typography className={classes.subtitle} variant="subtitle1">
                No calculado
              </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" px={1}>
              <Typography className={classes.subtitle} variant="subtitle1">
                Iva:
              </Typography>
              <CurrencyFormat
                value={details.tax}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
                renderText={(value) => (
                  <Typography className={classes.subtitle} variant="subtitle1">
                    {value}
                  </Typography>
                )}
              />
            </Box>
            <Box display="flex" justifyContent="space-between" px={1}>
              <Typography className={classes.subtitle} variant="subtitle1">
                Total:
              </Typography>
              <CurrencyFormat
                value={details.subTotal}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
                renderText={(value) => (
                  <Typography className={classes.subtitle} variant="subtitle1">
                    {value}
                  </Typography>
                )}
              />
            </Box>
            <Box mt={3} display="flex" flexDirection="column" alignItems="center">
              {isDisabled ? (
                <Box display="flex" justifyContent="center">
                  <Typography
                    variant="caption"
                    color="error"
                    style={{display: 'block', maxWidth: 300, textAlign: 'center'}}
                  >
                    Algunos productos ya no están disponibles, por favor elimínelos para continuar.
                  </Typography>
                </Box>
              ) : (
                <AppButton
                  onClick={handleCheckout}
                  round
                  variant="contained"
                  color="secondary"
                  {...(productsCount ? {} : {disabled: true})}
                >
                  Comprar{' '}
                  <CurrencyFormat
                    value={details.subTotal}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                    renderText={(value) => <span>&nbsp;{value}</span>}
                  />
                </AppButton>
              )}
            </Box>
          </Box>
        </div>
      </Slide>
    </Modal>
  );
};

export default ShoppingCart;
