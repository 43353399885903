/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {getCategoriesTree} from '../redux/actions/categories';
import {SELECTOR_CATEGORIES_TREE} from '../redux/selectors/categories';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import clsx from 'clsx';
import {S3_HOST} from '../enviroment';
// const useStylesRenderSubMenu = makeStyles((theme) => ({}));

function RenderSubMenu({level, categoriesTree}) {
  // const classes = useStylesRenderSubMenu();

  return (
    <ul className={clsx('subMenu', `lv-${level}`)}>
      {categoriesTree.map((category) => (
        <RenderMenuItem key={category.id} level={level} category={category} />
      ))}
    </ul>
  );
}

const useStylesRenderMenuItem = makeStyles((theme) => ({
  itemContent: {
    padding: theme.spacing(0, 0, 0, 1),
    height: 50,
    cursor: 'pointer',
    '&, & *': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, .1)',
    },
  },
  icon: {
    width: 30,
    height: 30,
    marginRight: 10,
  },
  iconArrow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 50,
    height: 50,
  },
}));

function RenderMenuItem({level, category}) {
  const classes = useStylesRenderMenuItem();
  const history = useHistory();

  const renderSubmenu = !!category.children.length;
  
  return (
    <li className={'item'}>
      <div
        className={clsx(classes.itemContent, 'itemContent')}
        onClick={() => {
          history.push(`/${category.slug}`);
        }}
      >
        <div style={{marginRight: 10}}>
          {category.path_image && (
            <img
              className={classes.icon}
              src={`${S3_HOST}/${category.path_image}`}
              alt={category.name}
            />
          )}
          {category.name}
        </div>
        {renderSubmenu && (
          <div className={classes.iconArrow}>
            {level === 0 ? <ExpandMoreIcon /> : <KeyboardArrowRightIcon />}
          </div>
        )}
      </div>
      {renderSubmenu && (
        <RenderSubMenu level={level + 1} categoriesTree={category.children} />
      )}
    </li>
  );
}

const useStylesCategoriesTopBar = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    zIndex: 1000,
    '& *': {
      color: 'white',
      textTransform: 'uppercase',
      fontWeight: 400,
    },
    '& ul': {
      listStyle: 'none',
      margin: 0,
      padding: 0,
      backgroundColor: theme.palette.primary.main,
      zIndex: 1000,
      '& li': {
        margin: 0,
        padding: 0,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    '& > ul': {
      position: 'relative',
      display: 'flex',
    },
    '& .item': {
      position: 'relative',
      '& > .subMenu': {
        position: 'absolute',
        display: 'none',
        minWidth: 200,
        '&:not(.lv-1)': {
          top: 0,
          left: '100%',
        },
      },
      '&:hover > .subMenu': {
        display: 'inline-block',
      },
    },
  },
}));

export default function CategoriesTopBar() {
  const categoriesTree = useSelector(SELECTOR_CATEGORIES_TREE);

  const classes = useStylesCategoriesTopBar();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCategoriesTree());
  }, []);

  return (
    <div className={classes.root}>
      <div>
        <div className={classes.content}>
          <ul>
            {categoriesTree.map((category) => (
              <RenderMenuItem key={category.id} level={0} category={category} />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
