import React, {useState, useEffect} from 'react';
import {Box, Dialog, Fade, makeStyles, useMediaQuery, withStyles} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {useLocation, matchPath} from 'react-router-dom';
import {getFullPicture} from '../modules/imageHandler';
import moment from 'moment';
import api from '../services/api';

const useCookieRemember = ({type} = {type: 'next-remember'}) => {
  const cookieName = 'remember-popup-v2';

  const [remember, setRemember] = useState(false);

  const mountHook = () => {
    const cookie = window.localStorage.getItem(cookieName);

    if (!cookie) {
      setRemember(true);
      return;
    }

    const now = moment().format('YYYY-MM-DD');
    const cookieRemember = moment(cookie).format('YYYY-MM-DD');

    if (type === 'next-remember') {
      if (now === cookieRemember) setRemember(true);
    } else {
      if (now !== cookieRemember) setRemember(true);
    }
  };

  const rememberMeIn = (days = 8) => {
    const rIn = moment().add(days, 'days').format('YYYY-MM-DD');
    window.localStorage.setItem(cookieName, rIn);
    setRemember(false);
  };

  const lastRemember = () => {
    const today = moment().format('YYYY-MM-DD');
    window.localStorage.setItem(cookieName, today);
  };

  useEffect(() => {
    mountHook();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    remember,
    rememberMeIn,
    lastRemember,
  };
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade in ref={ref} {...props} />;
});

const CustomDialog = withStyles((theme) => ({
  paper: {
    maxWidth: '90%',
    maxHeight: 200,
    [theme.breakpoints.up('sm')]: {
      maxWidth: 480,
      maxHeight: 300,
    },
    background: 'transparent',
    borderRadius: 10,
    overflow: 'inherit',
    cursor: 'pointer',
  },
}))(React.forwardRef((props, ref) => <Dialog ref={ref} {...props} />));

const useStyles = makeStyles((theme) => ({
  close: {
    position: 'absolute',
    top: -15,
    right: -15,
    background: theme.palette.primary.main,
    border: 0,
    borderRadius: '50%',
    cursor: 'pointer',
    color: 'white',
    width: 30,
    height: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
  },
  img: {
    display: 'flex',
    borderRadius: 10,
    overflow: 'hidden',
    backgroundColor: 'white',
  },
}));

export default function PopupHome() {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [picture, setPicture] = useState('');

  const location = useLocation();

  const {remember, lastRemember} = useCookieRemember({type: 'last-remember'});

  const isMobil = useMediaQuery('(max-width: 768px)');
  const classes = useStyles();

  const _fetchBanner = async () => {
    setLoading(true);
    await api.banners
      .find({query: {type: 'POPUP'}})
      .then(({data}) => data[0])
      .then(async (it) => {
        setData(it);
        setPicture(await getFullPicture(isMobil ? it?.path_movile : it?.path_desktop));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!data?.id && !loading) _fetchBanner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data?.id && remember && matchPath('/', {path: location.pathname, exact: true})) {
      // setTimeout(() => setOpen(true), 3000);
      setOpen(true);
    }
  }, [location.pathname, data, remember]);

  return (
    <CustomDialog TransitionComponent={Transition} handleClose={() => undefined} open={open}>
      <button
        className={classes.close}
        onClick={() => {
          setOpen(!open);
          lastRemember();
        }}
        aria-label="close"
      >
        <CloseIcon fontSize="small" />
      </button>

      <Box clone className={classes.img}>
        <img
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setOpen(false);
            window.open(data?.url, '_blank');
          }}
          src={picture}
          alt="popup"
        />
      </Box>
    </CustomDialog>
  );
}
